<template>
  <div>
    <div class="carousel-form">
      <el-select
        v-model="status"
        style="width:300px"
        placeholder="全部"
        @change="init()"
      >
        <el-option label="待审核" value="1"></el-option>
        <el-option label="审核通过" value="2"></el-option>
        <el-option label="未通过审核" value="3"></el-option>
      </el-select>
    </div>
    <el-dialog
      title="审核"
      :visible.sync="dialogVisible"
      width="60%"
      :before-close="handleClose"
    >
      <div>
        <el-button-group>
          <el-button
            type="primary"
            icon="el-icon-arrow-left"
            size="mini"
            @click="prePage"
            >上一页</el-button
          >
          <el-button type="primary" size="mini" @click="nextPage"
            >下一页<i class="el-icon-arrow-right el-icon--right"></i
          ></el-button>
        </el-button-group>
        <div style="marginTop: 10px; color: #409EFF">
          {{ pageNum }} / {{ pageTotalNum }}
        </div>
        <pdf
          :page="pageNum"
          :src="url"
          @progress="loadedRatio = $event"
          @num-pages="pageTotalNum = $event"
        ></pdf>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-table :data="tableData" style="width: 100%;margin-top: 20px;">
      <el-table-column label="id" width="180">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.id }}</span>
        </template>
      </el-table-column>
      <el-table-column label="资源名称" width="180">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.name }}</span>
        </template>
      </el-table-column>
      <el-table-column label="发布者" width="180">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.UserauthorName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="资源内容" width="180">
        <template slot-scope="scope">
          <el-popover trigger="hover" placement="top">
            <p>名称: {{ scope.row.name }}</p>
            <p>内容: {{ scope.row.img }}</p>
            <div slot="reference" class="name-wrapper">
              <el-tag size="medium">{{ scope.row.name }}</el-tag>
            </div>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column label="状态" width="180">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.sstate }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="280">
        <template slot-scope="scope">
          <el-button size="mini" @click="handleEdit(scope.$index, scope.row)"
            >查看</el-button
          >
          <el-button
            size="mini"
            type="success"
            @click="handleSucces(scope.$index, scope.row)"
            >通过</el-button
          >
          <el-button
            size="mini"
            type="danger"
            @click="handleDelete(scope.$index, scope.row)"
            >拒绝</el-button
          >
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { INSERT, QUERY, DELETE, UPDATE } from "@/services/dao.js";
import { getTimeFormat } from "@/js/common";
import pdf from "vue-pdf";
export default {
  components: {
    pdf,
  },
  data() {
    return {
      tableData: [],
      dialogVisible: false,
      status: "1",
      word: "",
      url: "http://image.cache.timepack.cn/nodejs.pdf",
      pageNum: 1,
      pageTotalNum: 1, // 总页数
      loadedRatio: 0, // 当前页面的加载进度，范围是0-1 ，等于1的时候代表当前页已经完全加载完成了
    };
  },
  created() {
    this.init();
  },
  methods: {
    handleEdit(index, row) {
      this.dialogVisible = true;
      this.url = row.file;
      console.log(index, row);
    },
    async handleDelete(index, row) {
      let data = await UPDATE(
        "POST",
        "",
        'update_ZYDocument_all(_set:{state: "' +
          3 +
          '"}, where: {id: {_eq:' +
          row.id +
          "}}) {  affected_rows }"
      );
      this.init();
    },
    async handleSucces(index, row) {
      let data = await UPDATE(
        "POST",
        "",
        'update_ZYDocument_all(_set:{state: "' +
          2 +
          '"}, where: {id: {_eq:' +
          row.id +
          "}}) {  affected_rows }"
      );
      this.init();
    },
    handleClose(done) {
      this.dialogVisible = false;
    },
    async init() {
      let res = await QUERY(
        "post",
        "",
        'ZYDocument_ALL( where: {state: {_eq: "' +
          this.status +
          '"}},limit: ' +
          10 +
          ", offset: " +
          0 +
          ") { id state  name  UserauthorName   Price Time  file}"
      );
      for (let i = 0; i < res.data.ZYDocument_ALL.length; i++) {
        res.data.ZYDocument_ALL[i].Time = getTimeFormat(
          Date.parse(res.data.ZYDocument_ALL[i].Time)
        );
        switch (res.data.ZYDocument_ALL[i].state) {
          case "1":
            res.data.ZYDocument_ALL[i].sstate = "待审核";
            break;
          case "2":
            res.data.ZYDocument_ALL[i].sstate = "审核通过 ";
            break;
          case "3":
            res.data.ZYDocument_ALL[i].sstate = "未通过审核";
            break;
        }
      }
      this.tableData = res.data.ZYDocument_ALL;
      for (var i = 0; i < this.tableData.length; i++) {
        this.tableData[i].file = this.api.LoginURL.concat(
          this.tableData[i].file
        );
      }
    },
    prePage() {
      let page = this.pageNum;
      page = page > 1 ? page - 1 : this.pageTotalNum;
      this.pageNum = page;
    },

    // 下一页
    nextPage() {
      let page = this.pageNum;
      page = page < this.pageTotalNum ? page + 1 : 1;
      this.pageNum = page;
    },
  },
};
</script>
